<template>
   <el-select
           v-model="businessType"
           clearable
           placeholder="选择账户类型"
           class="filter-item"
           style="width: 130px"
           @change="changeHandle"
   >
      <el-option
              v-for="item in TypeOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
</template>

<script>
  export default {
    name: 'searchCommunity',
    data() {
      return {
        businessType: '',
        TypeOptions: [
          { key: '1', display_name: 'Social' },
          { key: '2', display_name: 'MAM'}
        ],
      }
    },
    methods: {
      changeHandle() {
        this.$emit('toQuery');
      }
    }
  }
</script>

<style scoped>

</style>
